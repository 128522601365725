$fa-font-path: 'https://static.legendsoflearning.com/fonts/FontAwesome6';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import './tippy-animations-fade';
@import './shapes-mask';

@font-face {
  font-family: 'GROBOLD';
  src: url('https://static.legendsoflearning.com/fonts/grobold.ttf');
  font-display: swap;
}

body {
  @apply bg-gray-50;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .mask-curve {
    @apply [mask:url('../../public/images/layout/curve.svg')_no-repeat_bottom/cover];
    @apply absolute inset-0 bottom-auto box-content h-2/4;
    @apply bg-gradient-to-r bg-gradient-blue;
  }

  .before\:mask-curve::before {
    @extend .mask-curve;
    content: '';
  }

  .mask-student-shape {
    @apply [mask:url('../../public/images/layout/student-background-shape.svg')_no-repeat_top/contain];
    @apply absolute inset-0 bottom-auto box-content h-2/4 -z-10;
    @apply bg-gradient-to-r bg-gradient-blue;
  }

  .before\:mask-student-shape::before {
    @extend .mask-student-shape;
    content: '';
  }

  .bg-gradient {
    &-blue {
      @apply from-teal-400 to-cyan-600;
    }

    &-orange {
      @apply from-orange-400 to-red-600;
    }

    &-yellow {
      @apply from-yellow-300 to-amber-500;
    }

    &-purple {
      @apply from-indigo-500 to-violet-400;
    }

    &-gray {
      @apply from-slate-500 to-slate-700;
    }

    &-navy {
      @apply from-blue-900 to-indigo-900;
    }

    &-pink {
      @apply from-purple-400 to-orange-500;
    }

    &-neutral {
      @apply from-neutral-300 to-slate-500;
    }

    &-teal {
      @apply from-cyan-600 to-cyan-800;
    }

    &-sky {
      @apply from-cyan-400 to-blue-600;
    }
  }

  .bg-basecamp {
    @apply bg-gradient-sky bg-center bg-cover;
  }

  .bg-basecamp-radial {
    @apply bg-basecamp bg-[url('/images/layout/basecamp-pattern.svg'),_radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))];
  }

  .bg-basecamp-linear {
    @apply bg-basecamp bg-[url('/images/layout/basecamp-pattern.svg'),_linear-gradient(to_top_right,_var(--tw-gradient-stops))];
  }

  .bg-mbc {
    @apply bg-[url('/images/layout/mbc-pattern.svg'),_linear-gradient(to_bottom,_var(--tw-gradient-stops))];
    @apply from-[#1481E0] to-[#2FBAE9] bg-center bg-cover;
  }

  .bg-basecamp-student {
    @apply mask-student-shape bg-basecamp-radial;
    @apply h-96 pt-6;
  }

  .bg-basecamp-student-full {
    @apply bg-basecamp-radial;
    @apply h-96 pt-6;
  }

  .bg-teacher-curve {
    @apply [mask:url('../../public/images/layout/oval-2-1.svg')_no-repeat_top/contain];
    @apply bg-gradient-to-r from-purple-300 to-indigo-600;
  }

  .bg-radial-right {
    background-position-y: 50%;
    background-size: 100% 180%;
    background-image: radial-gradient(
      ellipse at right,
      var(--tw-gradient-from) 54%,
      var(--tw-gradient-to) 70%
    );
  }

  .bg-radial-bottom {
    background-position-x: 50%;
    background-size: 230% 50%;
    background-repeat: no-repeat;
    background-image: radial-gradient(
      ellipse at bottom,
      var(--tw-gradient-from) 30%,
      var(--tw-gradient-to) 70%
    );
  }

  .bg-assessment-orange {
    background: rgb(255, 169, 46);
    background: linear-gradient(90deg, #f6be5a 0%, #f2a155 100%);
  }

  .text-shadow {
    text-shadow: 1px 1px 1px rgba(0, 26, 65, 0.2);
  }

  .lol-gameframe {
    iframe {
      @apply rounded-lg shadow-2xl;
    }
  }
}

.lol-bg-top-gradient {
  background-position: 50% -50px;
  background-size: 2000px;
  background-repeat: no-repeat;
}
