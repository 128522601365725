.mask-bubble-2xl {
  @apply [mask:url('/images/awakening/shapes/bubble-2xl.svg')_no-repeat_center/contain];
  @apply w-[496px] h-[478px] bg-gradient-to-r from-teal-400/20 to-cyan-600/20;
}

.before\:mask-bubble-2xl::before {
  @extend .mask-bubble-2xl;
  @apply content-[""];
}

.mask-bubble-xl {
  @apply [mask:url('/images/awakening/shapes/bubble-xl.svg')_no-repeat_center/contain];
  @apply w-[474px] h-[462px] bg-gradient-to-tr from-cyan-300 to-teal-700;
  @apply bg-gradient-blue;
}

.before\:mask-bubble-xl::before {
  @extend .mask-bubble-xl;
  @apply content-[""];
}

.mask-bubble-lg {
  @apply [mask:url('/images/awakening/shapes/bubble-lg.svg')_no-repeat_center/contain];
  @apply w-[215px] h-[174px] bg-gradient-to-tr from-sky-300 to-sky-600;
}

.before\:mask-bubble-lg::before {
  @extend .mask-bubble-lg;
  @apply content-[""];
}

.mask-bubble-md {
  @apply [mask:url('/images/awakening/shapes/bubble-md.svg')_no-repeat_center/contain];
  @apply w-[215px] h-[174px] bg-gradient-to-br from-sky-300 to-sky-600;
}

.before\:mask-bubble-md::before {
  @extend .mask-bubble-md;
  @apply content-[""];
}

.mask-bubble-sm {
  @apply [mask:url('/images/awakening/shapes/bubble-sm.svg')_no-repeat_center/contain];
  @apply w-[91px] h-[75px] bg-gradient-to-tr from-teal-400/50 to-cyan-600/50;
}

.before\:mask-bubble-sm::before {
  @extend .mask-bubble-sm;
  @apply content-[""];
}

.mask-bubble-xs {
  @apply [mask:url('/images/awakening/shapes/bubble-xs.svg')_no-repeat_center/contain];
  @apply w-[49px] h-[47px] bg-gradient-to-tr from-indigo-400/20 to-purple-600/20;
}

.before\:mask-bubble-xs::before {
  @extend .mask-bubble-xs;
  @apply content-[""];
}

.mask-mountain-school-hours {
  @apply [mask:url('/images/awakening/shapes/mountain-school-hours.svg')_no-repeat_center/contain];
  @apply w-[437px] h-[452px] bg-neutral-600;
}

.mask-mountain {
  @apply [mask:url('/images/awakening/shapes/mountain.svg')_no-repeat_center/contain];
  @apply w-[437px] h-[448px] bg-neutral-600;
}
